import "./App.css";
import { Card, Space,Typography } from 'antd';
import TokenSearch from "./components/TokenSearch";
import { useState } from "react";
import ChainSelect from "./components/ChainSelect";
import checkHoneypot from "./actions/checkHoneypot";
import checkVerified from "./actions/checkVerified";


import blacklist from "./BlackList";

const { Text } = Typography;
const interpretations = {
  "UNKNOWN": <p style= {{color:"#C8BF39", fontWeight:"500", fontSize:"20px", fontFamily:"Times New Roman, Georgia, serif"}}>The token is unknown. This is usually a system error but could also be a bad sign for the token. Watchout.</p>,
  "OK": <p style= {{color:"#15F63B", fontWeight:"500", fontSize:"20px", fontFamily:"Times New Roman, Georgia, serif"}}>Does not look like a honeypot.
  This can always change!!! Do your own research.</p>, 
  "NO_PAIRS": <p style= {{color:"#C8BF39", fontWeight:"500", fontSize:"20px", fontFamily:"Times New Roman, Georgia, serif"}}> There is No trading pair for this token.</p>, 
  "SEVERE_FEE": <p style= {{color:"#D8836E", fontWeight:"500", fontSize:"20px", fontFamily:"Times New Roman, Georgia, serif"}}>Does not look like a honeypot. WARNING !!!!!! There is extremely high trading fee.Effectively a honeypot. Be Careful !!</p>, 
  "HIGH_FEE": <p style= {{color:"#15F63B  ", fontWeight:"500", fontSize:"20px", fontFamily:"Times New Roman, Georgia, serif"}}> Does not look like a honeypot. but there is high trading fee.</p>, 
  "MEDIUM_FEE": <p style= {{color:"#15F63B", fontWeight:"500", fontSize:"20px", fontFamily:"Times New Roman, Georgia, serif"}}> Does not look like a honeypot. This can always change!!! Do your own research. </p>, 
  "APPROVE_FAILED":  <p style= {{ fontWeight:"800",color:"#F31111", fontSize:"20px", fontFamily:"Times New Roman, Georgia, serif"}}> This is a HONEYPOT !!!! Stay Away 🏃🏃🏃🏃 </p> , 
  "SWAP_FAILED": <p style= {{ fontWeight:"800",color:"#F31111", fontSize:"20px", fontFamily:"Times New Roman, Georgia, serif"}}> This is a HONEYPOT !!!! Stay Away 🏃🏃🏃🏃 </p> 
}

const verifiedMessage = {
  true: <p style ={{fontWeight:"500",color:"#15F63B", fontSize:"20px", fontFamily:"Times New Roman, Georgia, serif"}}>The contract is verified</p>,
  false: <p style ={{fontWeight:"500",color:"#F31111", fontSize:"20px", fontFamily:"Times New Roman, Georgia, serif"}}> The contract is not verified. Watchout !!!!!.</p>
}

const doCheckHoneypot = checkHoneypot();
const doCheckVerified = checkVerified();


function App() {
  
  const [chain, setChain] = useState("bsc");

  const [loadingHoneypot, setLoadingHoneypot] = useState(false);
  const [loadingVerified, setLoadingVerified] = useState(false);
  const [status, setStatus] = useState(undefined);
  const [verified, setVerified] = useState(undefined);

  const onStart = async (tokenAddress_) => {
    if (
      tokenAddress_ === undefined ||
      chain === undefined || loadingVerified || loadingHoneypot) {
      return;
    }
    
    if (chain !== "avax") {
      setLoadingVerified(true);
      doCheckVerified(tokenAddress_, chain).then(s => {
        setVerified(s);
        setLoadingVerified(false);
      });
    }

    if(isBlacklisted(chain, tokenAddress_)) {
      setStatus("UNKNOWN");
      return;
    }

    setLoadingHoneypot(true);
    doCheckHoneypot(tokenAddress_, chain).then(s => {
      setStatus(s);
      setLoadingHoneypot(false);
    });
  };

  const onSelect = async (chain_) => {
    console.log(chain_);
    setChain(chain_)
  };

  return (

    <div style= {{

  textAlign: "center",
  minHeight: "100vh",
  maxWidth: "100vw",
  background: "#00002e",
  overflow: "hidden",
    }}className="App">
     
      <div className ="headings">
    <h1 style={{textAlign:"center", color: "#ff9c00", marginTop:"80px",marginBottom:"20px", maxWidth:"100%" }}>Check Honeypot Tokens</h1>
  </div>
  <p  style={{ color: "#ffffff", fontSize:"20px",
maxWidth:"100%", textAlign:"center" }}>
    Honeypot detector simulates a buy and a sell transaction to determine if a token is a honeypot. <br/> <br/>
    <br/>Join the Community <a  style={{color: "#cd5c5c" , maxWidth:"100%"}} href="https://t.me/KooKer_Official">  👨‍👨‍👧‍👦  https://t.me/KooKer_Official</a></p>
  <p style={{ color:"#1AE8E8", fontSize:"20px", maxWidth:"100%",  textAlign:"center"}}>  Please select a chain and paste token address to get started !!!!  </p>
    
      <Space direction="vertical" style={{ width: "100%" }}>
        
          <div style={{ paddingTop:"30px",paddingBottom:"30px",alignContent:"center", width: "220px",maxWidth:"100%", marginBottom: "100px", margin:"auto" }}>
            <ChainSelect onSelect={onSelect} disabled={loadingHoneypot || loadingVerified} />
          </div>
          <div style={{margin:"auto", alignContent:"center", width: "340px", maxWidth:"100%", alignItems:"center", alignSelf:"center", justifyContent:"center", textAlign:"center" }}>
            <TokenSearch onStart={onStart} disabled={loadingHoneypot || loadingVerified} />
          </div>
        


        <Card title=" Token Results" bordered={false} style={{ width: "100%", backgroundColor: "transparent" , padding:"20px"}}>
         
          {loadingHoneypot || loadingVerified ? <div>  </div> : <></>}
          {status === undefined ?
            <div style={{ color: "#ffffff", fontSize:"20px",
            maxWidth:"100%",  textAlign:"start" }}>
              
            
            <p>Please Do your own research. The fact that it is not a Honeypot now does not mean the results won't change in the future !!!!! use this service at your own risk.</p>
          </div>
            :
            <div>
              <Text code style={{ fontSize: "16px" }}>{interpretations[status]}</Text>
            </div>
          }
          {verified === undefined ? <></> : <div style={{ marginTop: "10px" }}>
            <Text code style={{ fontSize: "16px" }}>{verifiedMessage[verified]}</Text>
          </div>}
        </Card>
      </Space>
    </div>
  );
}

function isBlacklisted(chain, address) {
  if (!(chain in blacklist))
    return false;
  const chainBlacklist = blacklist[chain];
  if(!(address.toLowerCase() in chainBlacklist))
    return false;
  return chainBlacklist[address.toLowerCase()];
}

export default App;
