import { Input } from "antd";

const { Search } = Input;


function TokenSearch(props) {
  const { onStart, disabled } = props;
  return (
    <Search
    
      placeholder = "Enter Token Address"
      
      enterButton="Check Token"
      size="large"
      
      onSearch={onStart}
      disabled={disabled}
    />
  );
}

export default TokenSearch;
